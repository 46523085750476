import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { PermittedAccess } from 'components/common/PermittedAccess'
import { NotFoundError } from 'components/renderError'
import { Permission } from 'constants/permission'
import { HomePageLayout } from 'pages/home/homePageLayout/HomePageLayout'
import { routesManager } from 'utils/routesManager'

const Dashboard = lazy(() => import('pages/home/dashboard/Dashboard'))
const DashboardEdit = lazy(() => import('pages/home/dashboardEdit/DashboardEdit'))

export const getHomeRoutes = () => {
  return (
    <Route path={routesManager.home.root.routePath} element={<HomePageLayout />}>
      <Route index element={<Dashboard />} />

      <Route
        path={routesManager.home.edit.routePath}
        element={
          <PermittedAccess permission={Permission.OS_WIDGETS_ENTITIES_MANAGE}>
            <DashboardEdit />
          </PermittedAccess>
        }
      />

      <Route path="*" element={<NotFoundError />} />
    </Route>
  )
}
