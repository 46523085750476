import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'
import { HubsListFiltersApi } from 'pages/admin/hubs/hubsListFiltersSideModal/utils'
import { HubsType } from 'types/hubs/hubs'

export type Params = PaginationParams<{
  tenantId: string
  searchName?: string
  sort?: string
  filters?: HubsListFiltersApi
}>

export type HubsResponse = PaginatedResponse<HubsType>

export const fetchHubsApi = ({ page, itemsPerPage, tenantId, searchName, sort, filters }: Params) =>
  facadeApi.get<HubsResponse>('/hubs', {
    params: skipEmptyParams({
      page,
      itemsPerPage,
      sort,
      filter: { searchName, ...filters },
    }),
    headers: {
      'X-Tenant-Id': tenantId,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
