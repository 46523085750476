import { ThemeStyles } from '@platform-ui-kit/components-library'

import { AnalyticsAction } from '../analytics'
import { MayBeNull } from '../common'
import { NavigationTree } from '../mapping'
import { PermissionsRecord } from '../permissions'
import { Taxonomy } from '../taxonomy'
import { Tenant } from '../tenant'
import { UserDetails } from '../user'

export type OpenMenuHandler = (preselectedNodeAzId?: MayBeNull<string>) => void
export type OpenAppHandler = (appInstanceId: string) => void
export type OpenCompactAppHandler = (params: {
  name: string
  url: string
  overlayProps: Record<string, unknown>
}) => void

export type AppContextUnsubscribeFn = () => void

export enum AppErrorType {
  ForbiddenPage = 'Forbidden page',
  CriticalError = 'Critical error',
}

export enum OsContextActivePage {
  Promo = 'PROMO_PAGE',
  Home = 'HOME_PAGE',
  Network = 'NETWORK_PAGE',
  Hubs = 'HUBS_PAGE',
  // any pages related to marketplace
  Marketplace = 'MARKETPLACE_PAGE',
  // any pages related to dev-hub
  DevHub = 'DEVHUB_PAGE',
  // any pages related to orchestration
  Orchestration = 'ORCHESTRATION_PAGE',
  // any pages in OS Setting'
  OsSettings = 'OS_SETTINGS_PAGE',
  UserProfile = 'USER_PROFILE_PAGE',
  Links = 'LINKS_PAGE',
  News = 'NEWS_PAGE',
  PageNotFound = 'NOT_FOUND_PAGE',
  PageForbidden = 'FORBIDDEN_PAGE',
}

export type TriggerErrorHandler = (errorType: AppErrorType) => void

export interface OsCommonContext {
  tenant: Tenant
  permissions: PermissionsRecord[]
  userDetails: UserDetails
  theme: ThemeStyles
  navigationTree: NavigationTree
  taxonomy: Taxonomy
  activePage: MayBeNull<OsContextActivePage>
}

export interface OsApiContext {
  getAccessToken: () => string
  navigation: {
    openMenu: OpenMenuHandler
    openApp: OpenAppHandler
    openCompactApp: OpenCompactAppHandler
    triggerError: TriggerErrorHandler
  }
  analytics: {
    track: (data: AnalyticsAction) => void
  }
}
