import { WppButton } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'

import styles from 'pages/login/loginView/LoginView.module.scss'
import { getIdpsAnalyticsPayload, mapTenantIdpAliasToAuthIdp } from 'pages/login/utils'
import { IdentityProvider } from 'types/auth/identityProvider'
import { TenantIdp } from 'types/tenants/tenant'
import { trackAnalytics } from 'utils/analytics'

interface Props {
  idp: TenantIdp
  login: (identityProvider?: IdentityProvider) => void
}

export const IdpOption = ({ idp, login }: Props) => {
  const idpEventPayload = getIdpsAnalyticsPayload(idp.alias)

  return (
    <li>
      <WppButton
        data-testid={idp.alias}
        className={styles.identityProvider}
        variant="secondary"
        onClick={() => {
          login(mapTenantIdpAliasToAuthIdp(idp.alias))
          idpEventPayload &&
            trackAnalytics({
              type: AnalyticsActionType.page,
              payload: idpEventPayload,
            })
        }}
      >
        {!!idp.logo && (
          <img slot="icon-start" alt={`${idp.alias} icon`} src={idp.logo?.url || ''} width="20" height="20" />
        )}
        {idp.title}
      </WppButton>
    </li>
  )
}
