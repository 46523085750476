import { AnalyticsActionType } from '@wpp-open/core'
import { useEffect } from 'react'

import { ANALYTICS_EVENTS } from 'constants/analytics'
import { LoginView, LoginProps } from 'pages/login/loginView/LoginView'
import { trackAnalytics } from 'utils/analytics'

export const LoginPage = ({ login }: LoginProps) => {
  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.PAGE.LOGIN,
    })
  }, [])

  return <LoginView login={login} />
}
