import { UserDetails, MayBeNull, AnalyticsActionType, Tenant } from '@wpp-open/core'
import { useEffect, useMemo } from 'react'

import { ANALYTICS_EVENTS } from 'constants/analytics'
import { environment } from 'environment'
import { analytics, trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { noop } from 'utils/common'

export interface InitSegmentAnalyticsParams {
  currentTenant: MayBeNull<Tenant>
  userDetails: MayBeNull<UserDetails>
  roles: MayBeNull<{ role_name: string }[]>
}

export const useInitSegmentAnalytics = process.env.DEV
  ? noop
  : ({ currentTenant, userDetails, roles }: InitSegmentAnalyticsParams) => {
      const identifyPayload = useMemo(() => {
        if (currentTenant && userDetails && roles) {
          const uniqueRoles = roles.filter(
            (el, idx, arr) => arr.findIndex(obj => obj.role_name === el.role_name) === idx,
          )

          const { agency, country, createdAt, firstname, lastname, email, id } = userDetails
          const domain = email.match(/(?:@).*(?=[.])/)![0].replace('@', '')

          return {
            id,
            firstname,
            lastname,
            email,
            agency,
            tenantAzId: currentTenant.azId,
            createdAt,
            country,
            domain,
            roles: uniqueRoles,
          }
        }

        return null
        // using only userDetails will cause multiple re-renders for the useEffect because the object
        // is the same, only the reference changes. By checking the individual values prevents this
        // behaviour from happening
        // eslint-disable-next-line
      }, [
        userDetails?.id,
        userDetails?.email,
        userDetails?.lastname,
        userDetails?.firstname,
        userDetails?.createdAt,
        userDetails?.country,
        userDetails?.agency,
        currentTenant,
        roles,
      ])

      useEffect(() => {
        if (currentTenant) {
          const init = async () => {
            await (analytics.plugins as any).segment.group(`v4-${environment.LEGACY_BOX_ENV}-${currentTenant.name}`, {
              tenantId: currentTenant.azId,
              environment: environment.LEGACY_BOX_ENV,
              osVersion: 'v4',
              tenantName: currentTenant.name,
            })

            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: `Tenant ${currentTenant.name}`,
            })
          }

          init()
        }
      }, [currentTenant])

      useEffect(() => {
        if (identifyPayload) {
          const init = async () => {
            const { id, ...rest } = identifyPayload

            await analytics.identify(id, rest)
          }

          init()
        }
      }, [identifyPayload])

      useEffect(() => {
        const navigatedFrom = new URLSearchParams(window.location.search).get('navigatedFrom')

        if (navigatedFrom && userDetails) {
          const init = async () => {
            trackAppOpenAnalytics({
              productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
              productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
              userDetails,
              launchedFrom: navigatedFrom,
            })
          }

          init()
        }
        // eslint-disable-next-line
      }, [userDetails?.id, window.location.search])
    }
