import { useNavigate } from 'react-router-dom'

import { useInfiniteHubsListApi } from 'api/hubs/infiniteQueries/useInfiniteHubsListApi'
import { useFetchHubApi } from 'api/hubs/queries/useFetchHubApi'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { HubType } from 'types/hubs/hubs'
import { isString } from 'utils/common'
import { routesManager } from 'utils/routesManager'

export const useRedirectToDefaultHubOrSystemHub = () => {
  const { currentTenant } = useCurrentTenantData()
  const navigate = useNavigate()
  const { userDetails } = useOtherTenantsAndUserData()
  const hubId = userDetails?.preferredHubPerTenant?.[currentTenant.id]

  const { data: systemHub } = useInfiniteHubsListApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      tenantId: currentTenant.id,
      filters: { type: HubType.System, isActive: '', organizationUnitIds: [] },
    },
  })

  const { data: preferedHub } = useFetchHubApi({
    params: {
      hubId,
    },
    enabled: isString(hubId),
  })

  const hub = isString(hubId) && preferedHub ? preferedHub : systemHub[0]

  const urlToRedirect = hub ? routesManager.hubs.root.url(hub.id) : routesManager.home.root.url()

  const redirectToDefaultHubOrSystemHub = () => navigate(urlToRedirect)

  return { redirectToDefaultHubOrSystemHub, urlToRedirect, hub }
}
