import { NavigationItemEventDetail, NavigationState } from '@platform-ui-kit/components-library'
import {
  WppActionButton,
  WppGrid,
  WppIconChevron,
  WppInput,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
  WppSkeleton,
  WppSpinner,
  WppTag,
  WppTopbarItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { RefCallback, useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useInfiniteHubsListApi } from 'api/hubs/infiniteQueries/useInfiniteHubsListApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { openIntercomWidget } from 'components/intercom/utils'
import { showRequestTenantModal } from 'components/modal/requestTenantModal/RequestTenantModal'
import { NavigationMenu } from 'components/navigationMenu/NavigationMenu'
import { OsAssistant } from 'components/osAssistant/OsAssistant'
import { handleOpenWidget } from 'components/zendesk'
import { WIDGET_PAGE_CODES } from 'components/zendesk/utils/constants'
import { ANALYTICS_EVENTS, LaunchLocations, OsModuleNames } from 'constants/analytics'
import { Delay } from 'constants/delay'
import { Permission } from 'constants/permission'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useHasPermission } from 'hooks/useHasPermission'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useRedirectToDefaultHubOrSystemHub } from 'hooks/useRedirectToDefaultHubOrHomePage'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { Breadcrumbs } from 'layout/header/breadcrumbs/Breadcrumbs'
import { useBreadcrumbs } from 'layout/header/breadcrumbs/utils'
import styles from 'layout/header/Header.module.scss'
import { HeaderNavigationProvider } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { HeaderTeleport } from 'layout/header/HeaderTeleport'
import { NotificationsCenter } from 'layout/header/notificationsCenter/NotificationsCenter'
import { UserProfileIconMenu } from 'layout/header/userProfileIconMenu/UserProfileIconMenu'
import { getHeaderAppTag, HelpNavigationValue, isHelpNavigationItem, useHeaderMenu } from 'layout/header/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { HubType } from 'types/hubs/hubs'
import { getAnalyticsEventPayload, trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { simulateLinkClick } from 'utils/links'
import { routesManager } from 'utils/routesManager'

export const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { defaultTenant } = useOsRoot()
  const { urlToRedirect, hub: preferredHub } = useRedirectToDefaultHubOrSystemHub()
  const { availableTenants, requestableTenants, userDetails } = useOtherTenantsAndUserData()
  const { currentTenant } = useCurrentTenantData()
  const { appData } = useOsState()
  const breadcrumbs = useBreadcrumbs()
  const headerNavigation = useHeaderMenu()
  const { logoThumbnail, logoOriginal } = useTenantLogoAndMetaData()
  const ref = useRef<HTMLDivElement | null>(null)
  const refTenantSearch = useRef<HTMLWppInputElement>(null)
  const refHubSearch = useRef<HTMLWppInputElement>(null)
  const { hubId } = useParams()

  const isBreadcrumbsShown = Boolean(breadcrumbs.items.length)
  const navigationItems = isBreadcrumbsShown ? headerNavigation.slice(0, 1) : headerNavigation
  const contextNavigationItems = isBreadcrumbsShown ? headerNavigation.slice(1) : []
  const selectedNavItemValue = headerNavigation.find(({ path }) => path && pathname.startsWith(path))?.value || ''
  const { hasPermission } = useHasPermission()

  const [{ searchTenant, searchHub, loadMoreRef }, setState] = useSetState<{
    searchTenant: string
    searchHub: string
    loadMoreRef: HTMLDivElement
  }>({
    searchTenant: '',
    searchHub: '',
    loadMoreRef: null!,
  })

  const {
    data: hubs,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useInfiniteHubsListApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      searchName: searchHub,
      tenantId: currentTenant.id,
      sort: 'name',
      filters: { isActive: hasPermission(Permission.OS_HUB_MANAGE) ? '' : 'true', organizationUnitIds: [] },
    },
    staleTime: 60 * 1000,
  })

  useLayoutEffect(() => {
    if (ref?.current && hubs?.length) {
      setTimeout(() => {
        ref.current?.scrollIntoView()
      })
    }
  }, [ref, hubs])

  const setRef: RefCallback<HTMLDivElement> = useCallback(
    node => {
      setState({ loadMoreRef: node! })
    },
    [setState],
  )

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const sortedTenants = useMemo(() => {
    const tenants = availableTenants
      .filter(tenant => tenant.id !== currentTenant.id)
      .sort((a, b) => a.name.localeCompare(b.name))

    return searchTenant
      ? tenants.filter(({ name }) => name.toLowerCase().includes(searchTenant.toLowerCase()))
      : tenants
  }, [availableTenants, currentTenant.id, searchTenant])

  const showTenantList = !!sortedTenants.length || !!requestableTenants.length

  const handleTrackAnalytics = (productName: string) => {
    trackAppOpenAnalytics({
      productName,
      productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
      userDetails,
      launchedFrom: LaunchLocations.NavigationMenu,
    })
  }

  const handleNavigateMenu = (event: CustomEvent<NavigationItemEventDetail>) => {
    const { path, value } = event.detail

    switch (value) {
      case HelpNavigationValue.Intercom: {
        trackAnalytics({
          type: AnalyticsActionType.action,
          payload: getAnalyticsEventPayload(
            ANALYTICS_EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK,
            HelpNavigationValue.Intercom,
          ),
        })
        openIntercomWidget()
        break
      }

      case HelpNavigationValue.Marketplace: {
        handleTrackAnalytics(ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.MARKETPLACE)
        simulateLinkClick({ href: path! })
        break
      }

      case HelpNavigationValue.Support: {
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: getAnalyticsEventPayload(
            ANALYTICS_EVENTS.HOME_PAGE.ACTIONS.HOME_NAV_MENU_ITEM_CLICK,
            HelpNavigationValue.Support,
          ),
        })
        handleOpenWidget({
          userDetails,
          appOrPageCode: getHeaderAppTag(appData) || WIDGET_PAGE_CODES.OS_INTERNAL_PAGE,
        })
        break
      }

      default: {
        if (path) {
          handleTrackAnalytics(OsModuleNames[value])
          if (isHelpNavigationItem(value)) {
            simulateLinkClick({ href: path, target: '_blank' })
          } else {
            navigate(path)
          }
        }
        break
      }
    }
  }

  const setSearchDebounced = useDebounceFn(
    (key: string, search?: string) => setState({ [key]: search?.trim() || '' }),
    Delay.Search,
  )

  return (
    <HeaderNavigationProvider>
      <header className={styles.root}>
        <Flex className={styles.content} align="center">
          <WppGrid container>
            <HeaderTeleport.Target as={WppGrid} item all={24}>
              {/* Default OS header. Will be replaced with the deep most HeaderTeleport.Source rendered inside app */}
              <HeaderTeleport.Source>
                <Flex align="center" gap={12}>
                  <NavigationMenu />

                  <Flex align="center">
                    <Link
                      to={urlToRedirect}
                      onClick={() =>
                        trackAppOpenAnalytics({
                          productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
                          productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                          userDetails,
                          launchedFrom: LaunchLocations.NavigationMenu,
                          hub_id: preferredHub?.id,
                          hub_name: preferredHub?.name,
                          is_system_hub: preferredHub?.type === HubType.System,
                        })
                      }
                    >
                      {isBreadcrumbsShown ? (
                        <img className={styles.logoCompact} src={logoThumbnail} alt="logo" data-testid="logo-compact" />
                      ) : (
                        <img className={styles.logo} src={logoOriginal} alt="logo" data-testid="logo" />
                      )}
                    </Link>

                    {showTenantList && (
                      <WppMenuContext>
                        <WppActionButton
                          className={styles.tenantMenuTriggerButton}
                          slot="trigger-element"
                          variant="secondary"
                          data-testid="tenant_select"
                          onClick={() => {
                            setState({ searchTenant: '' })
                            refTenantSearch.current?.setValue('')
                            trackAnalytics({
                              type: AnalyticsActionType.action,
                              payload: ANALYTICS_EVENTS.LIST_OF_TENANTS.ACTIONS.LIST,
                            })
                          }}
                        >
                          <WppIconChevron className={styles.tenantMenuTriggerIcon} slot="icon-start" direction="down" />
                        </WppActionButton>

                        {(sortedTenants.length > 0 || searchTenant) && (
                          <>
                            {(sortedTenants.length > 3 || searchTenant) && (
                              <WppMenuGroup withDivider>
                                <WppListItem disabled className={styles.searchInputItem}>
                                  <WppInput
                                    ref={refTenantSearch}
                                    slot="label"
                                    size="s"
                                    type="search"
                                    className={styles.searchInput}
                                    onWppChange={({ detail }) => setSearchDebounced('searchTenant', detail.value)}
                                    placeholder={t('os.user_profile.search_workspace_placeholder')}
                                  />
                                </WppListItem>
                              </WppMenuGroup>
                            )}

                            <WppMenuGroup className={styles.tenantMenuGroup} withDivider={!!requestableTenants.length}>
                              {sortedTenants.length
                                ? sortedTenants.map(tenant => (
                                    <WppListItem
                                      key={tenant.id}
                                      linkConfig={{
                                        href: process.env.DEV
                                          ? '/'
                                          : `${tenant.homeUrl}?navigatedFrom=${LaunchLocations.ListTenants}`,
                                      }}
                                      onClick={() =>
                                        trackAnalytics({
                                          type: AnalyticsActionType.page,
                                          payload: getAnalyticsEventPayload(
                                            ANALYTICS_EVENTS.LIST_OF_TENANTS.PAGE.OPEN_TENANT,
                                            tenant.name,
                                          ),
                                        })
                                      }
                                    >
                                      <Avatar
                                        slot="left"
                                        name={tenant.name}
                                        src={tenant.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined}
                                      />
                                      <span slot="label">{tenant.name}</span>
                                    </WppListItem>
                                  ))
                                : null}
                            </WppMenuGroup>
                          </>
                        )}
                        {!!requestableTenants.length && (
                          <WppListItem
                            onWppChangeListItem={() =>
                              showRequestTenantModal({
                                title: t('os.request_access_modal.tenant.access_another_os'),
                                tenants: requestableTenants,
                              })
                            }
                          >
                            <span slot="label">{t('os.header.tenant_selection.request_access')}</span>
                          </WppListItem>
                        )}
                        {/* This limits the min-width of the list, but allows other items to expand further */}
                        <div className={styles.menuMinWidthLimiter} />
                      </WppMenuContext>
                    )}
                  </Flex>

                  {isBreadcrumbsShown && <Breadcrumbs data={breadcrumbs} />}

                  <Flex className={styles.navigationControls} gap={20}>
                    <Flex gap={4} align="center">
                      <WppMenuContext>
                        <WppActionButton
                          data-testid="header-hubs-dropdown"
                          className={styles.hubsMenuTriggerButton}
                          slot="trigger-element"
                          variant="secondary"
                          onClick={() => {
                            setState({ searchHub: '' })
                            refHubSearch.current?.setValue('')
                            trackAnalytics({
                              type: AnalyticsActionType.action,
                              payload: ANALYTICS_EVENTS.LIST_OF_HUBS.ACTIONS.LIST,
                            })
                          }}
                        >
                          <WppTypography className={styles.hubsMenuButtonTitle} type="s-midi">
                            {t('os.header.hubs_selection.title')}
                          </WppTypography>

                          <WppIconChevron className={styles.tenantMenuTriggerIcon} slot="icon-end" direction="down" />
                        </WppActionButton>
                        <div>
                          {(hubs.length > 3 || searchHub) && (
                            <WppMenuGroup withDivider>
                              <WppListItem disabled className={styles.searchInputItem}>
                                <WppInput
                                  ref={refHubSearch}
                                  slot="label"
                                  size="s"
                                  type="search"
                                  className={styles.searchInput}
                                  onWppChange={({ detail }) => setSearchDebounced('searchHub', detail.value)}
                                  placeholder={t('os.header.hubs_selection.search_placeholder')}
                                />
                              </WppListItem>
                            </WppMenuGroup>
                          )}
                          {preferredHub && (
                            <WppMenuGroup withDivider>
                              <WppListItem
                                key={preferredHub.id}
                                checked={hubId === preferredHub.id}
                                onWppChangeListItem={() => {
                                  trackAppOpenAnalytics({
                                    productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
                                    productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                                    launchedFrom: LaunchLocations.NavigationMenu,
                                    userDetails,
                                    hub_id: preferredHub?.id,
                                    hub_name: preferredHub?.name,
                                    is_system_hub: preferredHub?.type === HubType.System,
                                  })
                                  navigate(routesManager.hubs.root.url(preferredHub.id))
                                }}
                              >
                                <Avatar
                                  slot="left"
                                  name={preferredHub.name}
                                  src={preferredHub.logo?.url || undefined}
                                />

                                <WppTypography slot="label" type="s-body">
                                  {preferredHub.name}
                                </WppTypography>

                                <WppTag slot="right" label={t('os.common.default')} variant="neutral" />
                              </WppListItem>
                            </WppMenuGroup>
                          )}
                          <WppMenuGroup>
                            {hubs.length > 0 &&
                              hubs.map(hub => {
                                if (preferredHub && hub.id === preferredHub.id) {
                                  return null
                                }
                                return (
                                  <WppListItem
                                    key={hub.id}
                                    checked={hubId === hub.id}
                                    onWppChangeListItem={() => {
                                      trackAppOpenAnalytics({
                                        productName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HOME_PAGE,
                                        productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                                        launchedFrom: LaunchLocations.NavigationMenu,
                                        userDetails,
                                        hub_id: hub?.id,
                                        hub_name: hub?.name,
                                        is_system_hub: hub?.type === HubType.System,
                                      })
                                      navigate(routesManager.hubs.root.url(hub.id))
                                    }}
                                  >
                                    <Avatar slot="left" name={hub.name} src={hub.logo?.url || undefined} />

                                    <WppTypography slot="label" type="s-body">
                                      {hub.name}
                                    </WppTypography>
                                  </WppListItem>
                                )
                              })}

                            {isLoading && (
                              <WppListItem disabled className={styles.searchInputItem}>
                                <WppSkeleton width={310} height={24} slot="label" />
                              </WppListItem>
                            )}
                            {hasNextPage && (
                              <Flex justify="center" ref={setRef}>
                                {isFetching && !isLoading && (
                                  <WppListItem disabled className={styles.searchInputItem}>
                                    <WppSpinner size="s" slot="label" className={styles.loader} />
                                  </WppListItem>
                                )}
                              </Flex>
                            )}
                          </WppMenuGroup>
                          <div className={styles.menuMinWidthLimiter} />
                        </div>
                      </WppMenuContext>

                      {navigationItems.map(navigationItem => (
                        <WppTopbarItem
                          key={navigationItem.value}
                          firstLevel
                          data-testid={navigationItem.value}
                          navigation={navigationItem}
                          activeItems={[selectedNavItemValue]}
                          onWppActiveTopbarItemChange={handleNavigateMenu}
                        />
                      ))}

                      {contextNavigationItems.length > 0 && (
                        <WppTopbarItem
                          firstLevel
                          menu
                          navigation={
                            {
                              children: contextNavigationItems,
                            } as NavigationState
                          }
                          active={contextNavigationItems.some(({ value }) => selectedNavItemValue === value)}
                          activeItems={[selectedNavItemValue]}
                          onWppActiveTopbarItemChange={handleNavigateMenu}
                        />
                      )}
                    </Flex>

                    <OsAssistant />

                    <NotificationsCenter />

                    <UserProfileIconMenu />
                  </Flex>
                </Flex>
              </HeaderTeleport.Source>
            </HeaderTeleport.Target>
          </WppGrid>
        </Flex>
      </header>
    </HeaderNavigationProvider>
  )
}
