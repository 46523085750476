import { WppActionButton, WppCard, WppIconCross, WppTypography } from '@platform-ui-kit/components-library-react'
import { Rnd } from 'react-rnd'

import { CompactApp, CompactAppConfig } from 'components/apps/compact/CompactApp'
import { Portal } from 'components/common/portal/Portal'
import styles from 'providers/apps/compactAppOverlay/CompactAppOverlay.module.scss'

interface Props {
  app: CompactAppConfig
  overlayProps: Record<string, unknown>
  close: () => void
}

// TODO: Temporary approach for AI assistant
export const CompactAppOverlay = ({ app, overlayProps, close }: Props) => {
  return (
    <Portal>
      <Rnd className={styles.resizableContainer} default={{ x: 50, y: 50, width: 800, height: 500 }} {...overlayProps}>
        <WppCard size="l" className={styles.card}>
          <div slot="header">
            <WppTypography type="l-strong">{app.name}</WppTypography>
          </div>
          <div slot="actions">
            <WppActionButton variant="secondary" onClick={() => close()}>
              <WppIconCross slot="icon-start" />
            </WppActionButton>
          </div>

          <div className={styles.app}>
            <CompactApp app={app} />
          </div>
        </WppCard>
      </Rnd>
    </Portal>
  )
}
