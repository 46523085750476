import { getChildHierarchyLevels } from '@wpp-open/core'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserPermissionsApi } from 'api/alphaZulu/queries/useUserPermissionsApi'
import { useNavigationTreeApi } from 'api/navigation/queries/useNavigationTreeApi'
import { useUserAssignedRolesApi } from 'api/roles/queries/useUserAssignedRolesApi'
import { useTenantApi } from 'api/tenant/queries/useTenantApi'
import { is403Error } from 'api/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { useInitSegmentAnalytics } from 'hooks/useInitSegmentAnalytics'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { useAuth } from 'providers/auth/AuthContext'
import { CurrentTenantDataContext } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useTreeWithoutHiddenLevel } from 'utils/mapping/common'

/**
 * Provides necessary data for a non-generic current tenant
 */
export const CurrentTenantDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { hostInfo } = useOsRoot()
  const { userDetails } = useOtherTenantsAndUserData()

  const currentTenantId = hostInfo.currentTenant!.id

  const {
    data: currentTenantFull,
    isLoading: isCurrentTenantFullLoading,
    error: currentTenantFullError,
  } = useTenantApi({
    params: {
      tenantId: currentTenantId,
    },
  })

  const navigationHierarchy = useMemo(
    () => (currentTenantFull ? getChildHierarchyLevels(currentTenantFull) : []),
    [currentTenantFull],
  )

  const navigationHierarchyWithoutHiddenLevel = useMemo(
    () =>
      currentTenantFull && currentTenantFull.flags.isFirstLevelHidden && navigationHierarchy.length > 0
        ? navigationHierarchy.slice(1)
        : navigationHierarchy,
    [currentTenantFull, navigationHierarchy],
  )

  const {
    data: userAssignedRoles,
    isError: isUserAssignedRolesError,
    isLoading: isUserAssignedRolesLoading,
  } = useUserAssignedRolesApi({
    params: { email: userDetails.email, id: userDetails.id },
  })

  const {
    isLoading: isPermissionsLoading,
    data: permissions,
    isError: isPermissionsError,
  } = useUserPermissionsApi({
    params: {
      email: user.email,
      accountId: currentTenantFull?.azMeta.organizationsId!,
    },
    enabled: !!currentTenantFull,
  })

  const {
    isLoading: isNavigationTreeLoading,
    data: navigationTree,
    error: navigationTreeError,
  } = useNavigationTreeApi({
    params: {
      tenantId: currentTenantId,
    },
  })

  const navigationTreeWithoutHiddenLevel = useTreeWithoutHiddenLevel({
    currentTenant: currentTenantFull,
    tree: navigationTree,
  })

  useProviderNoncriticalError({
    isError: isUserAssignedRolesError,
    message: t('os.provider_errors.user_assigned_roles'),
  })

  useProviderNoncriticalError({
    isError: isPermissionsError,
    message: t('os.provider_errors.permissions'),
  })

  // without memoizing the value, at each rerender we would send the same object but with a different
  // reference that would cause some unnecessary rerendering inside the custom hook
  const roles = useMemo(() => {
    return !isUserAssignedRolesLoading
      ? userAssignedRoles.map(({ role_name }) => ({
          role_name,
        }))
      : null
  }, [userAssignedRoles, isUserAssignedRolesLoading])

  useInitSegmentAnalytics({
    currentTenant: currentTenantFull,
    userDetails,
    roles,
  })

  const isLoading = isCurrentTenantFullLoading || isPermissionsLoading || isNavigationTreeLoading
  const error = currentTenantFullError || navigationTreeError

  if (isLoading) {
    return <LoadingPage />
  }

  if (error) {
    return is403Error(error) ? <ForbiddenOSAccessError /> : <OsIsNotAvailableError />
  }

  return (
    <CurrentTenantDataContext.Provider
      value={{
        currentTenant: currentTenantFull!,
        permissions: permissions!,
        navigationHierarchy: navigationHierarchyWithoutHiddenLevel,
        navigationHierarchyWithHiddenLevel: navigationHierarchy,
        navigationTree: navigationTreeWithoutHiddenLevel!,
        navigationTreeWithHiddenLevel: navigationTree!,
      }}
    >
      {children}
    </CurrentTenantDataContext.Provider>
  )
}
