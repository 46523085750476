import segmentPlugin from '@analytics/segment'
import {
  AnalyticsAction,
  AnalyticsActionType,
  AnalyticsPageAction,
  AnalyticsTrackAction,
  DefaultHierarchyLevelType,
  MayBeNull,
  NavigationTree,
  UserDetails,
  HierarchyCustomNodeType,
} from '@wpp-open/core'
import Analytics from 'analytics'

import { LaunchLocations } from 'constants/analytics'
import { environment } from 'environment'
import { ProjectProcessType } from 'types/projects/project'
import { ProjectCanvasApplicationShort, ProjectPhaseShort, ProjectShort } from 'types/projects/projectNavigation'
import { excludeFalsy, isString, noop } from 'utils/common'
import { WorkspaceHierarchy } from 'utils/workspace'

interface AnalyticsAppTrackProps {
  activeHierarchyWithHiddenLevel?: MayBeNull<WorkspaceHierarchy>
  navigationTreeWithHiddenLevel?: MayBeNull<NavigationTree>
  productName?: string
  productType?: string
  appId?: MayBeNull<string>
  userDetails: UserDetails
  versionId?: MayBeNull<string>
  versionName?: MayBeNull<string>
  versionType?: MayBeNull<string>
  project?: MayBeNull<ProjectShort<ProjectProcessType>>
  projectPhase?: MayBeNull<ProjectPhaseShort>
  projectItem?: MayBeNull<ProjectCanvasApplicationShort>
  launchedFrom?: LaunchLocations | string
  hub_name?: string
  hub_id?: string
  is_system_hub?: boolean
}

export const analytics = Analytics({
  app: 'os-app',
  plugins: [
    !!environment.SEGMENT_ANALYTICS_KEY &&
      segmentPlugin({
        writeKey: environment.SEGMENT_ANALYTICS_KEY,
      }),
  ].filter(excludeFalsy),
})

export const reset = () => {
  analytics.reset()
}

const getAnalyticsTenantAzId = () => analytics?.getState()?.user?.traits?.tenantAzId

const trackPage = ({ payload }: AnalyticsPageAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    analytics.page(
      {
        title: payload,
      },
      {
        agency: analyticsTenantAzId,
      },
    )
  }
}

const trackAction = ({ payload }: AnalyticsTrackAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    if (isString(payload)) {
      analytics.track(payload, {
        agency: analyticsTenantAzId,
      })
    } else {
      const { action, params } = payload

      analytics.track(action, {
        agency: analyticsTenantAzId,
        ...Object.fromEntries(params.map(({ key, value }) => [key, value])),
      })
    }
  }
}

export const trackAnalytics = process.env.DEV
  ? noop
  : (action: AnalyticsAction) => {
      switch (action.type) {
        case AnalyticsActionType.page:
          trackPage(action)
          break

        case AnalyticsActionType.action:
          trackAction(action)
          break

        default:
          break
      }
    }

export const trackAppOpenAnalytics = ({
  activeHierarchyWithHiddenLevel,
  navigationTreeWithHiddenLevel,
  productName,
  productType,
  appId,
  userDetails,
  versionId,
  versionName,
  versionType,
  project,
  projectPhase,
  projectItem,
  launchedFrom,
  hub_name,
  hub_id,
  is_system_hub,
}: AnalyticsAppTrackProps) => {
  const activeHierarchyNodes = activeHierarchyWithHiddenLevel?.map(
    nodeId => navigationTreeWithHiddenLevel?.mapping[nodeId],
  )

  const client = activeHierarchyNodes?.find(node => node?.type === DefaultHierarchyLevelType.Client)
  const market = activeHierarchyNodes?.find(node => node?.type === DefaultHierarchyLevelType.Market)
  const brand = activeHierarchyNodes?.find(node => node?.type === DefaultHierarchyLevelType.Brand)
  const industry = activeHierarchyNodes?.find(node => node?.type === DefaultHierarchyLevelType.Industry)
  const region = activeHierarchyNodes?.find(node => node?.type === DefaultHierarchyLevelType.Region)
  const customs = activeHierarchyNodes?.filter(node => node?.type === HierarchyCustomNodeType)

  const customHierarchyLevel = customs
    ? customs.map(custom => ({
        levelCategory: custom?.customTypeName,
        levelValue: custom?.name,
      }))
    : null

  const data = {
    source: 'CORE',
    launchedWithContext: !!(client || market || brand || industry || region || customHierarchyLevel),
    market: market?.name || '',
    brand: brand?.name || '',
    client: client?.name || '',
    industry: industry?.name || '',
    region: region?.name || '',
    custom: customHierarchyLevel || [],
    productName: productName || '',
    productType: productType || '',
    appId: appId || '',
    userId: userDetails.id,
    userEmail: userDetails.email,
    versionId: versionId || '',
    versionName: versionName || '',
    versionType: versionType || '',
    projectId: project?.id || '',
    projectName: project?.name || '',
    projectType: project?.type || '',
    projectProcessType: project?.processType || '',
    phaseId: projectPhase?.id || '',
    phaseName: projectPhase?.name || '',
    taskId: projectItem?.task?.id || '',
    taskName: '',
    taskStatus: projectItem?.task?.status || '',
    launchedFrom: launchedFrom || '',
    hub_name: hub_name || '',
    hub_id: hub_id || '',
    is_system_hub: is_system_hub !== undefined ? is_system_hub : '',
  }

  const params = Object.entries(data).map(([key, value]) => ({
    key,
    value,
  }))

  trackAnalytics({
    type: AnalyticsActionType.action,
    payload: {
      action: 'app_launch',
      params: params,
    },
  })
}

export const getAnalyticsEventPayload = (event: string, itemName: string) => event.replace('{itemName}', itemName)
