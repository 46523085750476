import { MayBeNull } from '@wpp-open/content-builder'
import { differenceInMilliseconds } from 'date-fns'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateFeedsLastSeenAtApi } from 'api/feeds/mutations/useUpdateFeedsLastSeenAtApi'
import { useStableCallback } from 'hooks/useStableCallback'
import { EventName, Feed, FeedsStatusItem } from 'types/feeds/feeds'

export const REFETCH_INTERVAL = 10 * 1000
interface PollingProps {
  statuses: FeedsStatusItem[]
  tenantId: MayBeNull<string>
  queryAndInvalidateHandler: () => Promise<void>
}

export function useNotificationMessage() {
  const { t } = useTranslation()

  const getToastMessage = useCallback(
    (notification: Feed) => {
      const { context, eventName } = notification
      const { user_name, body, tenant_name } = context

      switch (eventName) {
        case EventName.HIERARCHY_ACCESS_REQUESTED_ADMIN:
          return t('os.notification.access.hierarchy.requested_access', { userName: user_name })
        case EventName.HIERARCHY_ACCESS_REJECTED:
          return t('os.notification.access.rejected_access', {
            type: t('os.notification.access.hierarchy.navigation'),
          })
        case EventName.HIERARCHY_ACCESS_APPROVED:
          return t('os.notification.access.approved_access', {
            type: t('os.notification.access.hierarchy.navigation'),
          })
        case EventName.TENANT_ACCESS_REQUESTED_ADMIN:
          return t('os.notification.access.tenant.requested_access', {
            userName: user_name,
            tenantName: tenant_name,
          })
        case EventName.ADMIN_NOTIFICATION_REQUEST:
          return body
        case EventName.NEWS_CREATED_NOTIFICATION:
          return body
        case EventName.APPS_NOTIFICATION:
          return t('os.notification.apps.new_notification')
      }
    },
    [t],
  )

  const getToastHeader = useCallback((notification: Feed) => {
    const { context, eventName } = notification
    if (eventName === EventName.APPS_NOTIFICATION) {
      const { title } = context
      return title
    }
    return notification.categories[0]?.toUpperCase()
  }, [])

  return { getToastMessage, getToastHeader }
}

export const useNotificationPollingCenter = ({ statuses, tenantId, queryAndInvalidateHandler }: PollingProps) => {
  const { mutateAsync: handleUpdateFeedsLastSeenAt } = useUpdateFeedsLastSeenAtApi()
  const { snoozedUntil: notificationCentreSnoozedTime = null } =
    statuses.find(({ tenantId: id }) => id === tenantId) || {}

  const pollingNotificationHandler = useStableCallback(() => {
    return setInterval(async () => {
      await queryAndInvalidateHandler()
    }, REFETCH_INTERVAL)
  })

  const snoozeNotificationHandler = useCallback(() => {
    const timeout = Math.max(differenceInMilliseconds(new Date(notificationCentreSnoozedTime!), new Date()), 0)

    return setTimeout(async () => {
      await handleUpdateFeedsLastSeenAt({ tenantId, snoozedUntil: null })
      await queryAndInvalidateHandler()
    }, timeout)
  }, [notificationCentreSnoozedTime, queryAndInvalidateHandler, handleUpdateFeedsLastSeenAt, tenantId])

  useEffect(() => {
    let interval: ReturnType<typeof pollingNotificationHandler>

    if (!notificationCentreSnoozedTime) {
      interval = pollingNotificationHandler()
    } else {
      snoozeNotificationHandler()
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [
    notificationCentreSnoozedTime,
    snoozeNotificationHandler,
    pollingNotificationHandler,
    tenantId,
    queryAndInvalidateHandler,
    handleUpdateFeedsLastSeenAt,
  ])
}
