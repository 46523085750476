import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppIconMail,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ANALYTICS_EVENTS } from 'constants/analytics'
import styles from 'pages/login/loginView/LoginView.module.scss'
import { IdentityProvider } from 'types/auth/identityProvider'
import { TenantIdp } from 'types/tenants/tenant'
import { trackAnalytics } from 'utils/analytics'

interface Props {
  hasOtherOptions: boolean
  emailIdp: TenantIdp
  login: (identityProvider?: IdentityProvider) => void
}

export const EmailIdpOption = ({ hasOtherOptions, emailIdp, login }: Props) => {
  const { t } = useTranslation()

  if (hasOtherOptions) {
    return (
      <>
        <Flex className={styles.dividerContainer} align="center" gap={8}>
          <WppDivider className={styles.divider} />
          <WppTypography type="xs-midi" className={styles.dividerText}>
            {t('os.common.or')}
          </WppTypography>
          <WppDivider className={styles.divider} />
        </Flex>

        <WppActionButton
          data-testid={emailIdp.alias}
          variant="secondary"
          className={styles.identityProvider}
          onClick={() => {
            login()
            trackAnalytics({
              type: AnalyticsActionType.page,
              payload: ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.ACTIONS.EMAIL,
            })
          }}
        >
          {emailIdp.title}
        </WppActionButton>
      </>
    )
  }

  return (
    <WppButton
      data-testid={emailIdp.alias}
      className={styles.identityProvider}
      variant="secondary"
      onClick={() => {
        login()
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.ACTIONS.EMAIL,
        })
      }}
    >
      <WppIconMail slot="icon-start" />
      {emailIdp.title}
    </WppButton>
  )
}
