import { WppTag } from '@platform-ui-kit/components-library/components/wpp-tag'
import { t } from 'i18next'
import { useMemo } from 'react'

import { HierarchyAccessRequestStatus } from 'api/accessRequests/fetchers/fetchHierarchyAccessRequestsListApi'
import { TenantAccessRequestStatus } from 'api/accessRequests/fetchers/fetchTenantAccessRequestsListApi'
import { Requests } from 'constants/requests'

export type RequestStatus = TenantAccessRequestStatus | HierarchyAccessRequestStatus

const getLabel = (requestType: Requests, status?: RequestStatus) => {
  if (isApproved(status)) {
    return requestType === Requests.HIERARCHY_ACCESS
      ? t('os.requests.statuses.assigned')
      : t('os.requests.statuses.approved')
  }

  if (isRejected(status)) {
    return t('os.requests.statuses.rejected')
  }
}

export const isApproved = (status?: RequestStatus) => {
  return status === HierarchyAccessRequestStatus.APPROVED || status === TenantAccessRequestStatus.APPROVED
}

export const isRejected = (status?: RequestStatus) => {
  return status === HierarchyAccessRequestStatus.REJECTED || status === TenantAccessRequestStatus.REJECTED
}

export const isCompleted = (status?: RequestStatus) => {
  return isApproved(status) || isRejected(status)
}

export const useStatusTagOptions = (
  requestType: Requests,
  status?: RequestStatus,
): {
  variant: WppTag['variant']
  label?: string
} => {
  const variant = useMemo(() => {
    if (isApproved(status)) {
      return 'positive'
    }
    if (isRejected(status)) {
      return 'negative'
    }
    return 'neutral'
  }, [status])

  const label = useMemo(() => getLabel(requestType, status), [requestType, status])

  return { variant, label }
}
