import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { useDefaultIdpsApi } from 'api/tenant/queries/useDefaultIdpsApi'
import { useTenantIdpsApi } from 'api/tenant/queries/useTenantIdpsApi'
import { Flex } from 'components/common/flex/Flex'
import { openIntercomWidget } from 'components/intercom/utils'
import { handleOpenWidget } from 'components/zendesk'
import { WIDGET_PAGE_CODES } from 'components/zendesk/utils/constants'
import { ANALYTICS_EVENTS } from 'constants/analytics'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { AuthLayout } from 'layout/authLayout/AuthLayout'
import { EmailIdpOption } from 'pages/login/loginView/emailIdpOption/EmailIdpOption'
import { IdpOption } from 'pages/login/loginView/idpOption/IdpOption'
import styles from 'pages/login/loginView/LoginView.module.scss'
import { showSignUpSideModal } from 'pages/login/signUpSideModal/SignUpSideModal'
import { getFilteredTenantIdps } from 'pages/login/utils'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOsSupportService } from 'providers/osSupportService/OsSupportServiceContext'
import { IdentityProvider } from 'types/auth/identityProvider'
import { HostType } from 'types/tenants/tenant'
import { trackAnalytics } from 'utils/analytics'

export interface LoginProps {
  className?: string
  login: (identityProvider?: IdentityProvider) => void
}

export const LoginView = ({ className, login }: LoginProps) => {
  const { t } = useTranslation()
  const { logoOriginal, tenantId } = useTenantLogoAndMetaData()
  const { hostInfo } = useOsRoot()
  const { intercom, zendesk } = useOsSupportService()
  const { isLoading: isTenantIdpsLoading, data: tenantIdps } = useTenantIdpsApi({
    params: {
      tenantId: tenantId!,
    },
    enabled: !!tenantId,
  })
  const { isLoading: isDefaultIdpsLoading, data: defaultIdps } = useDefaultIdpsApi()

  const isGenericTenant = hostInfo.type === HostType.GENERIC
  const isLoading = isTenantIdpsLoading || isDefaultIdpsLoading
  const { emailIdp, signUpOption, restIdps } = getFilteredTenantIdps({ isLoading, defaultIdps, tenantIdps })

  return (
    <AuthLayout className={className}>
      <Flex className={styles.root} direction="column" align="center" justify="center" gap={56}>
        <img className={styles.tenantLogo} alt="Tenant logo" src={logoOriginal} />

        <Flex className={styles.content} direction="column" align="center">
          <WppTypography className={styles.signInLabel} type="2xl-heading">
            {t('os.login.sing_in')}
          </WppTypography>

          <Flex as="ul" direction="column" gap={24}>
            {isLoading ? (
              <>
                <WppSkeleton width="100%" height={40} />
                <WppSkeleton width="100%" height={40} />
                <WppSkeleton width="100%" height={40} />
              </>
            ) : (
              restIdps.map(idp => <IdpOption key={idp.alias} idp={idp} login={login} />)
            )}
          </Flex>

          {!isGenericTenant && !!emailIdp && (
            <EmailIdpOption hasOtherOptions={restIdps.length > 0} emailIdp={emailIdp} login={login} />
          )}
        </Flex>

        <Flex direction="column" gap={28}>
          <Flex justify="center" gap={24}>
            <button
              className={styles.link}
              onClick={() => {
                if (intercom.active) {
                  openIntercomWidget()
                }

                if (zendesk.active) {
                  handleOpenWidget({ appOrPageCode: WIDGET_PAGE_CODES.LOGIN })
                }
              }}
            >
              <WppTypography type="s-body" data-testid="get-support">
                {t('os.help.get_support')}
              </WppTypography>
            </button>

            {tenantId && signUpOption && (
              <button
                className={styles.link}
                data-testid="sign-up"
                onClick={() => {
                  showSignUpSideModal({ tenantId, title: signUpOption.title || '' })

                  trackAnalytics({
                    type: AnalyticsActionType.page,
                    payload: ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.ACTIONS.SIGN_UP,
                  })
                }}
              >
                <WppTypography type="s-body">{signUpOption.title}</WppTypography>
              </button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </AuthLayout>
  )
}
